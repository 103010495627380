<template>
    <div>
        <el-form :model="form" ref="form" :rules="rules" label-width="100px" label-position="left" >
            <el-form-item label="手机号" prop="mobile">
                <el-input class="inputBox" :disabled='type=="editPass"?true:false' maxlength="12" clearable v-model="form.mobile" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="password">
                <el-input show-password class="inputBox" maxlength="20" clearable v-model="form.password" placeholder="请输入密码"></el-input>
                <div class="tips">
                    <span>密码只能包含数字、字母以及+-*/.!@#$%&*</span>
                </div>
            </el-form-item>
            <el-form-item label="密码确认" prop="rePassword">
                <el-input show-password class="inputBox" maxlength="20" clearable v-model="form.rePassword" placeholder="请再次输入密码"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="code">
                <div class="inputBox a-flex-rfsc">
                    <el-input class="a-flex" style="margin-right: 10px" maxlength="12" clearable v-model="form.code" placeholder="请再次输入密码"></el-input>
                    <el-button type="primary" :disabled='countDown<60?true:false' @click="getCode">{{ countDown == 60 ? '获取验证码' : countDown+'秒后重新获取'}}</el-button>
                </div>
            </el-form-item>
        </el-form>
        <div class="a-flex-cfsc a-fs-14" v-if="type == 'forgetPass'">
            <el-button type="primary" class="sureBtn a-flex-1" @click="submit">立即提交</el-button>
            <span class="a-c-blue a-ml-10 font-point" @click="cancelSubmit">去登录</span>
        </div>
        <div v-if="type== 'editPass'">
            <div style="height: 100px;"></div>
            <div class="a-line-t-e0 footerBox">
                <el-button type="primary" class="a-ml-24 a-mt-15" @click="submit">立即提交</el-button>
                <el-button class="a-ml-24 a-mt-15" style="margin-left: 24px" @click="cancelSubmit">&nbsp;&nbsp;&nbsp;返回&nbsp;&nbsp;&nbsp;</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import util from '../../../utils/util'
import Utils from '../../../utils/index'
import { mapGetters, mapState, mapActions } from "vuex";
var MD5 = require('md5.js')
export default {
    data() {
        var validatepwd = (rule, value, callback) => {
            const regex = /^[A-Za-z0-9\+\-\*\/\.\!\@\#\$\%\&\*]*$/;
            if (value === '') {
                callback(new Error('请输入密码'))
            } else if (!regex.test(value)) {
                callback(new Error('密码格式不正确'))
            } else {
                callback()
            }
        };
        var validateRepwd = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请确认密码'))
            } else if (value != this.form.password) {
                callback(new Error('两次密码输入不同'))
            } else {
                callback()
            }
        };
        return {
            id: '',
            util: util,
            form:{
                mobile: '',
                password: '',
                rePassword: '',
                code: '',
            },
            rules:{
                mobile: [{required: true, message:'请输入手机号', trigger: 'blur'}],
                password: [{required: true, validator: validatepwd, trigger: 'blur'}],
                rePassword: [{required: true, validator: validateRepwd, trigger: "blur"}],
                code: [{required: true, message:'请输入验证码', trigger: 'blur'}],
            },
            countDown: 60,
            timeInterval: null
        };
    },
    computed:{
        ...mapState({
			userInfo: state => state.user.user,
		})
    },
    props: {
        type: {
            default: ''
        }
    },
    mounted () {
      
    },
    watch:{
        userInfo: {
            immediate: true,
            deep: true,
            handler (val) {
                this.form.mobile = this.userInfo.mobile
            }
        }
    },
    methods: {
        ...mapActions({
            gooutLogin: "user/logout",
            setMenu: "menu/setMenu"
        }),
        getCode () {
            this.$refs['form'].validateField(['mobile'], (errorMessage) => {
                if (!errorMessage) {
                    this.$Axios._get({
                        url: this.$Config.apiUrl.mobileCode,
                        method: "get",
                        params: {
                            mobile: this.form.mobile
                        }
                    }).then(res=>{
                        if (res.result.code == 0) {
                            this.countDown--
                            this.timeInterval = setInterval(()=>{
                                this.countDown--
                                if(this.countDown <= 0) {
                                    clearInterval(this.timeInterval)
                                    this.countDown = 60
                                }
                            },1000)
                        } else {
                            this.$message.error(res.result.message)
                        }
                    })
                } 
            })
            
        },
        submit () {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.$confirm('是否确认提交？','温馨提示')
                    .then(_=>{
                        let reqData= {
                            mobile: this.form.mobile,
                            password: new MD5().update(this.form.password).digest('hex'),
                            rePassword: new MD5().update(this.form.rePassword).digest('hex'),
                            code: this.form.code,
                        }
                        this.$Axios._post({
                            url: this.$Config.apiUrl.resetPassword,
                            method: "post",
                            params: {
                                ...reqData
                            }
                        }).then(res=>{
                            if (res.result.code == 0) {
                                this.$message.success('操作成功')
                                if(this.type == 'forgetPass') {
                                    this.$emit('toLogin','success')
                                    Utils.removeCookie('mobile')
							        Utils.removeCookie('password')
                                    this.$refs['form'].resetFields()
                                    return
                                }
                                this.gooutLogin().then(() => {
                                    setTimeout(() => {
                                        this.$router.replace({
                                            path: "/login",
                                        });
                                        this.setMenu([])
                                    }, 1000);
                                });
                            } else {
                                this.$message.error(res.result.message)
                            }
                        })
                    })
                    .catch(err=>{
                        console.log(err);
                    })
                    
                }
            })
        },
        cancelSubmit () {
            if(this.type == 'forgetPass') {
                this.$emit('toLogin')
                return
            }
            this.$confirm('是否确认返回？','温馨提示')
            .then(_=>{
                this.$router.back()
                this.$store.dispatch("delView", this.$route);
            })
            .catch(_=>{})
        },
    },
}
</script>

<style lang="scss" scoped>
.inputBox{
    width: 400px;
}
.addBankBtn{
    padding: 0 20px;
    font-size: 14px;
    color: #007AFF;
    line-height: 34px;
    cursor: pointer;
}
.a-mb-10{
    margin-bottom: 10px;
}
/deep/ .coupon-item{
    &>div{
        margin-left: 0 !important;
    }
}
.stationTag{
    padding: 0px 6px;
    background: #f4f4f5;
    color: #909399;
    border: 1px solid #e9e9eb;
    border-radius: 3px;
    margin-right: 4px;
    margin-bottom: 4px;
    font-size: 12px;
    word-break: keep-all;
}
.tips{
    font-size: 12px;
    color: #909399;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    line-height: 22px;
}
.sureBtn{
    width: 80%;
    font-size: 14px;
    padding: 12px;
    background: #007AFF;
    margin-top: 32px;
    margin-bottom: 16px;
    border-radius: 4px;
}
</style>