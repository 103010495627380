<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <el-card class="monthCard-edit">
            <le-edit-password type='editPass'></le-edit-password>
        </el-card>
    </div>
</template>

<script>
import leEditPassword from '../components/page/le-edit-password.vue';
export default {
    components: {
        leEditPassword
    },
    data() {
       return {

       }
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.monthCard-edit{
    font-size: 14px;
    height: 100%;
    overflow-y: auto;
    /deep/ .s-search-label {
		width: 145px !important;
		justify-content: flex-start;
	}
    /deep/ .s-nomarl-input .el-input__inner {
		width: 200px;
	}
    /deep/ .date-range{
        margin: 0;
    }
    /deep/ .projectBox{
        &>div{
            margin: 0;
        }
    }
    /deep/ .el-dialog__wrapper{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    /deep/ .el-dialog__body{
        padding: 10px 20px !important;
    }
    /deep/ .el-dialog{
        margin: 0 auto !important;
    }
}
</style>